export const domainEventsRecorder = () => {
    let events = [];
    const record = (domainEvents) => {
        events = [...events, ...domainEvents];
    };
    const recordedDomainEvents = () => {
        const domainEvents = events;
        events = [];
        return domainEvents;
    };
    return { record, recordedDomainEvents };
};

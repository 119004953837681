export class SessionAdapter {
  constructor() {
    return this;
  }

  get(key) {
    return sessionStorage.getItem(key);
  }

  set(key, value) {
    if (value) sessionStorage.setItem(key, value);
    else sessionStorage.removeItem(key);
  }
}

export const domainEventsPublisher = () => {
    let subscribers = [];
    const publish = (domainEvents) => {
        domainEvents.forEach((domainEvent) => subscribers.forEach((subscriber) => subscriber(domainEvent)));
    };
    const subscribe = (subscriber) => {
        subscribers = [...subscribers, subscriber];
    };
    const unsubscribe = (subscriber) => {
        subscribers = subscribers.filter((aSubscriber) => aSubscriber !== subscriber);
    };
    return { publish, subscribe, unsubscribe };
};

export class MemoryAdapter {
  constructor() {
    this.store = {};

    return this;
  }

  get(key) {
    return this.store[key];
  }

  set(key, value) {
    if (value) this.store[key] = value;
    else delete this.store[key];
  }
}

import Cookies from 'js-cookie';

export class CookieAdapter {
  constructor() {}

  get(key) {
    try {
      return Cookies.get(key);
    } catch (error) {
      return undefined;
    }
  }

  set(key, value, expires = Infinity, path = '/') {
    if (value) Cookies.set(key, value, { expires, path });
    else Cookies.remove(key);
  }
}

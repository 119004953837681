/* eslint-disable no-async-promise-executor */
import { MemoryAdapter } from './adapters/memory';
import { decrypt, encrypt } from './modules';

const state = new WeakMap();

export class AsyncStorage {
  constructor(props = {}) {
    const { adapter: Adapter = MemoryAdapter, defaults = {}, secret } = props;

    return new Promise(async (resolve, reject) => {
      const adapter = await new Adapter({ defaults });

      state.set(this, { adapter, secret });

      try {
        Object.keys(defaults).forEach(async (key) => {
          await this.set(key, defaults[key]);
        });
        resolve(this);
      } catch (error) {
        reject(error);
      }
    });
  }

  get(key) {
    const { adapter, secret } = state.get(this);

    return new Promise(async (resolve, reject) => {
      try {
        const value = await adapter.get(key);
        resolve(value ? (secret ? decrypt(value, secret) : JSON.parse(value)) : undefined);
      } catch (error) {
        reject(error);
      }
    });
  }

  set(key, value, ...rest) {
    const { adapter, secret } = state.get(this);

    return new Promise(async (resolve, reject) => {
      try {
        resolve(await adapter.set(key, secret ? encrypt(value, secret) : JSON.stringify(value), ...rest));
      } catch (error) {
        reject(error);
      }
    });
  }

  has(key) {
    return new Promise(async (resolve, reject) => {
      try {
        resolve((await this.get(key)) !== undefined);
      } catch (error) {
        reject(error);
      }
    });
  }

  remove(key) {
    const { adapter } = state.get(this);

    return new Promise(async (resolve, reject) => {
      try {
        resolve(await adapter.set(key, undefined));
      } catch (error) {
        reject(error);
      }
    });
  }
}

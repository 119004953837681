import { SYNC_TRANSLATION_NAME } from "../../domain/translation/command/syncTranslation";
import { sync } from "../../domain/translation/model/translation";
import { getTranslationKeyByLocaleHandler, GET_TRANSLATION_KEY_BY_LOCALE_NAME, } from "../../projection/translation/getTranslationKeyByLocale";
import { viewTranslationByLocaleHandler, VIEW_TRANSLATION_BY_LOCALE_NAME, } from "../../projection/translation/viewTranslationByLocale";
import { getTranslation, saveTranslation } from "../domain/translation/model/storageTranslations";
import { read as asyncStorageRead, write as asyncStorageWrite } from "../persistence/asyncStorageStorage";
import { storageTranslationByLocaleView } from "../projection/translation/storageTranslationView";
export const bootstrap = ({ messagingBootstrap }) => ({ fetchTranslation, read = asyncStorageRead, write = asyncStorageWrite }) => messagingBootstrap
    .query(GET_TRANSLATION_KEY_BY_LOCALE_NAME, getTranslationKeyByLocaleHandler)
    .query(VIEW_TRANSLATION_BY_LOCALE_NAME, viewTranslationByLocaleHandler, {
    view: storageTranslationByLocaleView({ read }),
})
    .command(SYNC_TRANSLATION_NAME, sync, { fetchTranslation })(getTranslation, saveTranslation, { read, write })
    .build();

const DEFAULT_CONTEXT = {
  publish: () => {},
  subscribe: () => {},
  unsubscribe: () => {},
};

const EVENT = {
  // Non-imperative
  ACTION: 'ACTION',
  // Imperative
  BUSY: 'BUSY',
  SCROLL: 'SCROLL',
  // Notifications
  ERROR: 'ERROR',
  INFO: 'INFO',
  SUCCESS: 'SUCCESS',
};

export { DEFAULT_CONTEXT, EVENT };

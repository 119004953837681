import { locale } from "../../../../domain/translation/model/locale";
import { translationKey } from "../../../../domain/translation/model/translationKey";
import { translationMessages } from "../../../../domain/translation/model/translationMessages";
const toDomain = (translation) => ({
    aggregateId: translation.id,
    key: translationKey(translation.key),
    locale: locale(translation.locale),
    messages: translationMessages(translation.messages),
    domainEvents: [],
});
const fromDomain = (translation) => ({
    id: translation.aggregateId,
    key: translation.key,
    locale: translation.locale,
    messages: translation.messages,
});
export const getTranslation = ({ read }) => async (translationId) => {
    const translationDto = await read(translationId);
    if (!translationDto) {
        throw new Error(`Translation not found: ${translationId}`);
    }
    return toDomain(translationDto);
};
export const saveTranslation = ({ write }) => async (aggregateRoot) => await write(aggregateRoot.key, fromDomain(aggregateRoot));

/* eslint-disable no-console */
/* eslint-disable no-undef */

const IS_DEV = typeof __DEV__ !== 'undefined' ? __DEV__ : false;
const EXECUTE = IS_DEV && process.env.JEST_WORKER_ID === undefined;

export const shell = {
  log: (...params) => (EXECUTE ? console.log('⚪️', ...params) : undefined),

  info: (...params) => (EXECUTE ? console.info('ℹ️', ...params) : undefined),

  warn: (...params) => (EXECUTE ? console.warn('🟠', ...params) : undefined),

  error: (...params) => (EXECUTE ? console.error('🔴', ...params) : undefined),
};

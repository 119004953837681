var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { createRef, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { getInputErrors } from '../../../../../helpers';
import { ALIGN, COLOR, FLEX_DIRECTION, SIZE, styles } from '../../../../../hooks';
import { Input, KEYBOARD_TYPES, Text, View } from '../../../../primitives';
import { style } from './Date.style';
const DELIMITER = '/';
const createMap = (value) => value
    .split(DELIMITER)
    .map((part) => ({ label: part, type: part[0], length: part.length || 0 }));
const Date = forwardRef((_a, ref) => {
    var { accessibilityLabel, defaultValue, format = 'DD/MM/YYYY', formatValue = 'YYYY/MM/DD', max, min, value, onBlur, onChange, onError, onFocus } = _a, others = __rest(_a, ["accessibilityLabel", "defaultValue", "format", "formatValue", "max", "min", "value", "onBlur", "onChange", "onError", "onFocus"]);
    const [values, setValues] = useState(['', '', '']);
    const [showPlaceholders, setShowPlaceholders] = useState([true, true, true]);
    const [focus, setFocus] = useState(false);
    const formatMap = createMap(format);
    const formatValueMap = createMap(formatValue);
    const dayRef = createRef();
    const monthRef = createRef();
    const yearRef = createRef();
    useEffect(() => {
        setValues((prevValues) => {
            const newValues = [...prevValues];
            (value || defaultValue || '').split(DELIMITER).forEach((part, index) => {
                newValues[index] = part;
            });
            return newValues;
        });
    }, [value, defaultValue]);
    const getRef = (type) => (type === 'D' ? dayRef : type === 'M' ? monthRef : yearRef);
    useImperativeHandle(ref, () => getRef(formatMap[0].type).current);
    const handleBlur = (event) => {
        const empty = values.filter((part = '') => part.length !== 0).length === 0;
        empty && onBlur && onBlur(event);
    };
    const handleChange = (index, value = '') => {
        var _a;
        const { length, type } = formatValueMap[index];
        // State
        const nextValue = value.toString().substring(0, length).trim();
        const nextValues = JSON.parse(JSON.stringify(values));
        nextValues[index] = nextValue;
        setValues(nextValues);
        // Track placeholder visibility
        const nextShowPlaceholders = [...showPlaceholders];
        nextShowPlaceholders[index] = nextValue.length === 0;
        setShowPlaceholders(nextShowPlaceholders);
        // Events
        const errors = getInputErrors(Object.assign(Object.assign({}, others), { value: nextValues.join('/'), min, max }));
        onError && onError(errors);
        if (!errors) {
            const value = parseValue(nextValues);
            onChange && onChange(value);
            onBlur && onBlur({ customEvent: 'blurEvent' });
        }
        // Tab index
        if (nextValue.length === length) {
            const nextInputIndex = formatMap.findIndex((part) => part.type === type) + 1;
            if (formatMap[nextInputIndex])
                (_a = getRef(formatMap[nextInputIndex].type).current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    };
    const handleFocus = (index, event) => {
        var _a;
        if (!focus) {
            setFocus(true);
            (_a = getRef(formatMap[0].type).current) === null || _a === void 0 ? void 0 : _a.focus();
        }
        onFocus && onFocus(event);
    };
    const parseValue = (values) => values
        .map((part, index) => {
        const { length } = formatValueMap[index];
        return part.padStart(length, '0');
    })
        .join(DELIMITER);
    return (React.createElement(View, { alignItems: ALIGN.CENTER, flexDirection: FLEX_DIRECTION.ROW }, formatMap.map(({ type, label, length }, index) => {
        const firstItem = index === 0;
        const lastItem = index === formatMap.length - 1;
        const mapIndex = formatValueMap.findIndex((item) => item.type === type);
        const fieldAccessibilityLabel = `${accessibilityLabel ? accessibilityLabel + '-' : ''}${label}`;
        return (React.createElement(View, { key: type, alignItems: ALIGN.CENTER, flex: lastItem ? SIZE.XS : undefined, flexDirection: FLEX_DIRECTION.ROW },
            React.createElement(Input, Object.assign({}, others, { accessibilityLabel: fieldAccessibilityLabel, id: `${others.id}-${type}`, placeholder: label, ref: getRef(type), style: styles(style.input, !lastItem && style[`length${length}`], firstItem && style.firstInput, lastItem && style.lastInput, others.style), textAlign: ALIGN.LEFT, testID: others.testID ? `${others.testID}-${type}` : undefined, keyboardType: KEYBOARD_TYPES.number, value: values[mapIndex] || '', onBlur: (event) => (onBlur ? handleBlur(event) : undefined), onChange: (value) => handleChange(mapIndex, value), onFocus: (event) => handleFocus(index, event) })),
            !lastItem && (React.createElement(Text, { color: others.disabled ? COLOR.TEXT_MEDIUM_LIGHT : COLOR.TEXT_MEDIUM, style: style.inputSeparator }, "/"))));
    })));
});
Date.displayName = 'InputFieldDate';
export { Date };

import PropTypes from 'prop-types';
import React, { useContext, useMemo, useState, createContext } from 'react';

import { DEFAULT_CONTEXT } from './Event.definition';
import { filterCallback, getEventId, getStorage } from './helpers';

const EventContext = createContext(DEFAULT_CONTEXT);

const EventProvider = ({ children }) => {
  const [queue, setQueue] = useState([]);
  const [subscribers, setSubscribers] = useState(getStorage());

  const handlePublish = ({ domain, event } = {}, props) => {
    const eventId = getEventId(domain, event);

    setQueue([...queue, { domain, event, props, timestamp: new Date().getTime() }]);
    (subscribers[eventId] || []).forEach((callback) => callback.apply(undefined, [props]));
  };

  const handleSubscribe = ({ domain, event } = {}, callback) => {
    const eventId = getEventId(domain, event);
    const subscriber = subscribers[eventId];

    setSubscribers((previousState) => {
      previousState[eventId] = [...filterCallback(subscriber, callback), callback];
      return previousState;
    });
  };

  const handleUnsubscribe = ({ domain, event } = {}, callback) => {
    const eventId = getEventId(domain, event);
    const subscriber = subscribers[eventId];

    if (subscriber)
      setSubscribers((previousState) => {
        previousState[eventId] = filterCallback(subscriber, callback);
        return previousState;
      });
  };

  return useMemo(
    () => (
      <EventContext.Provider
        value={{
          publish: handlePublish,
          subscribe: handleSubscribe,
          unsubscribe: handleUnsubscribe,
        }}
      >
        {children}
      </EventContext.Provider>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [children],
  );
};

EventProvider.propTypes = {
  children: PropTypes.node,
  domain: PropTypes.string,
};

const useEvent = () => useContext(EventContext);

export { EventContext, EventProvider, useEvent };

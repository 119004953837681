import { shell } from '@helpers';

import { getUrl } from './modules';
import { HEADERS } from './request.config';

const SHELL_TITLE = 'request()';

const AbortControllerProxy = () =>
  typeof AbortController === 'undefined'
    ? {
        abort: () => {},
        signal: undefined,
      }
    : new AbortController();

export const request = async ({ endpoint, headers, hostname, method = 'GET', timeout, ...props } = {}) => {
  const controller = new AbortControllerProxy();

  if (timeout) setTimeout(() => controller.abort(), timeout);

  return new Promise((resolve, reject) => {
    const url = getUrl({ endpoint, hostname });

    fetch(url, {
      headers: { ...HEADERS, ...headers },
      method,
      signal: timeout ? controller.signal : undefined,
      ...(['DELETE', 'POST', 'PUT'].includes(method) ? { body: JSON.stringify(props) } : props),
    })
      .then(async (response) => {
        const text = await response.text();
        let json;

        if (text.length > 0) json = JSON.parse(text);
        if (response.status >= 400) {
          shell.error(SHELL_TITLE, url, { response });
          reject({ code: response.status, message: (json || {}).message, payload: json });
        } else {
          shell.info(SHELL_TITLE, url, { response: json });
          resolve(json);
        }
      })
      .catch(({ message = 'Something wrong happened. Try again.', response } = {}) => {
        shell.error(SHELL_TITLE, url, { response, message });
        reject({
          code: response ? response.status : 500,
          message,
        });
      });
  });
};

import { commandHandlingMiddleware } from "../domain/command/commandHandlingMiddleware";
import { domainEventPublishingMiddleware } from "../domain/command/domainEventPublishingMiddleware";
import { syncCommandBus } from "../domain/command/syncCommandBus";
import { domainEventsPublisher } from "../domain/event/domainEventsPublisher";
import { domainEventsRecorder } from "../domain/event/domainEventsRecorder";
import { inMemoryEventBus } from "../domain/event/inMemoryEventBus";
import { processableEventBus } from "../domain/event/processableEventBus";
import { syncQueryBus } from "../projection/syncQueryBus";
import { bootstrapDefinition } from "./bootstrapDefinition";
export const bootstrap = () => {
    const { query, command, processManager: baseProcessManager, build } = bootstrapDefinition();
    const instance = {
        query: (name, queryHandler, ...dependencies) => {
            query(name, queryHandler, ...dependencies);
            return instance;
        },
        command: (name, commandHandler, ...dependencies) => (get, save, ...repositoryDependencies) => {
            command(name, commandHandler, ...dependencies)(get, save, ...repositoryDependencies);
            return instance;
        },
        processManager: (name, processManager, ...dependencies) => {
            baseProcessManager(name, processManager, ...dependencies);
            return instance;
        },
        build: () => {
            const { queryDefinitions, commandDefinitions, processManagerDefinitions } = build();
            const eventsRecorder = domainEventsRecorder();
            const eventsPublisher = domainEventsPublisher();
            const queryBus = syncQueryBus({ queryDefinitions, queryBus: () => queryBus });
            const eventBus = inMemoryEventBus({ domainEventsRecorder: eventsRecorder });
            const commandBus = syncCommandBus({
                middlewares: [
                    commandHandlingMiddleware({ commandDefinitions, queryBus, eventBus }),
                    domainEventPublishingMiddleware({
                        eventBus: processableEventBus({ processManagerDefinitions, queryBus, commandBus: () => commandBus }),
                        domainEventsRecorder: eventsRecorder,
                        domainEventsPublisher: eventsPublisher,
                    }),
                ],
            });
            return {
                queryBus,
                commandBus,
                subscribe: eventsPublisher.subscribe,
                unsubscribe: eventsPublisher.unsubscribe,
            };
        },
    };
    return instance;
};

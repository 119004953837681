import { useMutation } from "@tanstack/react-query";
import { useCallback, useContext, useRef } from "react";
import invariant from "tiny-invariant";
import { getContext } from "./bootstrap";
export var CommandStatus;
(function (CommandStatus) {
    CommandStatus["IDLE"] = "idle";
    CommandStatus["LOADING"] = "loading";
    CommandStatus["SUCCESS"] = "success";
    CommandStatus["ERROR"] = "error";
})(CommandStatus || (CommandStatus = {}));
export const useCommand = ({ contextId } = {}) => {
    const { messaging: messagingContext, queryClient: queryClientContext } = getContext({ id: contextId });
    const { commandBus } = useContext(messagingContext);
    invariant(commandBus, "Your are trying to use the useCommand hook without wrapping your app with the <MessagingProvider>.");
    const dispatchCommand = useCallback(async (command) => await commandBus(command), [commandBus]);
    const mutation = useMutation(dispatchCommand, { context: queryClientContext });
    const mutationRef = useRef(mutation);
    mutationRef.current = mutation;
    const dispatchFn = useCallback(async (command) => {
        mutationRef.current.reset();
        await mutationRef.current.mutateAsync(command);
    }, []);
    return [dispatchFn, mutationRef.current.status];
};

export class LocalAdapter {
  constructor() {
    return this;
  }

  get(key) {
    return localStorage.getItem(key);
  }

  set(key, value) {
    if (value) localStorage.setItem(key, value);
    else localStorage.removeItem(key);
  }
}

import { locale as localeFunction } from "./locale";
import { translationKey } from "./translationKey";
import { translationSynced } from "./translationSynced";
export const sync = ({ fetchTranslation }) => async ({ aggregateRoot, command }) => {
    const aggregateId = command.aggregateId;
    const locale = localeFunction(command.locale);
    return {
        ...aggregateRoot,
        locale,
        key: translationKey(locale),
        messages: await fetchTranslation({ locale }),
        domainEvents: [translationSynced({ aggregateId, locale })],
    };
};

import { baseBootstrap, read as asyncStorageRead, write as asyncStorageWrite, } from "@lookiero/i18n";
import { bootstrap as messagingReactBootstrap } from "@lookiero/messaging-react";
import React, { useMemo } from "react";
import { useSyncTranslationForLocale } from "./behaviors/useSyncTranslationForLocale";
import { useViewTranslationForLocale } from "./behaviors/useViewTranslationForLocale";
const I18nConsumer = ({ locale, children, contextId }) => {
    const [translation] = useViewTranslationForLocale({ locale, contextId });
    useSyncTranslationForLocale({ locale, contextId });
    const value = useMemo(() => ({ locale, translation }), [locale, translation]);
    return children(value);
};
const i18nRoot = ({ fetchTranslation, read = asyncStorageRead, write = asyncStorageWrite, contextId = "I18n", }) => {
    const { Component } = baseBootstrap({ messagingBootstrap: messagingReactBootstrap({ id: contextId }) })({
        fetchTranslation,
        read,
        write,
    });
    // eslint-disable-next-line react/display-name, react/prop-types
    return ({ locale, children, includeDevTools }) => (React.createElement(Component, { cacheClearOnUnmount: false, includeReactQueryDevTools: includeDevTools },
        React.createElement(I18nConsumer, { contextId: contextId, locale: locale }, (value) => children(value))));
};
export { i18nRoot };

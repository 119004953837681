import { MemoryAdapter } from './adapters/memory';
import { decrypt, encrypt, getDefaultAdapter } from './modules';

const state = new WeakMap();

export class Storage {
  constructor(props = {}) {
    const { adapter: Adapter = MemoryAdapter, defaults = {}, secret } = props;
    const adapter = getDefaultAdapter(Adapter);

    try {
      state.set(this, { adapter, secret });
      Object.keys(defaults).forEach((key) => this.set(key, defaults[key]));
      return this;
    } catch (error) {
      throw Error(error);
    }
  }

  get(key) {
    const { adapter, secret } = state.get(this);

    try {
      const value = adapter.get(key);
      return value ? (secret ? decrypt(value, secret) : JSON.parse(value)) : undefined;
    } catch (error) {
      throw Error(error);
    }
  }

  set(key, value, ...rest) {
    const { adapter, secret } = state.get(this);

    try {
      return adapter.set(key, secret ? encrypt(value, secret) : JSON.stringify(value), ...rest);
    } catch (error) {
      throw Error(error);
    }
  }

  has(key) {
    try {
      return this.get(key) !== undefined;
    } catch (error) {
      throw Error(error);
    }
  }

  remove(key) {
    const { adapter } = state.get(this);

    try {
      return adapter.set(key, undefined);
    } catch (error) {
      throw Error(error);
    }
  }
}

import invariant from "tiny-invariant";
export const bootstrapDefinition = () => {
    /**
     * Generic values are not supported by TypeScript for now
     * so we are forced to define generics values as any
     * https://github.com/Microsoft/TypeScript/issues/17574
     */
    /* eslint-disable @typescript-eslint/no-explicit-any */
    const queryDefinitions = {};
    const commandDefinitions = {};
    const processManagerDefinitions = {};
    /* eslint-enable @typescript-eslint/no-explicit-any */
    const instance = {
        query: (name, queryHandler, ...dependencies) => {
            invariant(!queryDefinitions[name], `There is already registered a QueryHandler for: ${name}`);
            queryDefinitions[name] = [queryHandler, dependencies[0] || {}];
            return instance;
        },
        command: (name, commandHandler, ...dependencies) => (get, save, ...repositoryDependencies) => {
            invariant(!commandDefinitions[name], `There is already registered a CommandHandler for: ${name}`);
            commandDefinitions[name] = [
                commandHandler,
                dependencies[0] || {},
                { get, save, ...(repositoryDependencies[0] || {}) },
            ];
            return instance;
        },
        processManager: (name, processManager, ...dependencies) => {
            processManagerDefinitions[name] = [
                ...(processManagerDefinitions[name] || []),
                [processManager, dependencies[0] || {}],
            ];
            return instance;
        },
        build: () => ({ queryDefinitions, commandDefinitions, processManagerDefinitions }),
    };
    return instance;
};

import { useContext, useEffect, useRef } from "react";
import invariant from "tiny-invariant";
import { getContext } from "./bootstrap";
export const useEvent = ({ callback, filter = () => false, delay, contextId }) => {
    const { messaging: messagingContext } = getContext({ id: contextId });
    const { subscribe, unsubscribe } = useContext(messagingContext);
    invariant(subscribe, "Your are trying to use the useEvent hook without wrapping your app with the <MessagingProvider>.");
    const filterRef = useRef(filter);
    filterRef.current = filter;
    const callbackRef = useRef(callback);
    callbackRef.current = callback;
    useEffect(() => {
        let timeout;
        const subscriber = (event) => {
            if (!filterRef.current(event)) {
                return;
            }
            if (delay === undefined) {
                callbackRef.current();
                return;
            }
            clearTimeout(timeout);
            timeout = setTimeout(() => callbackRef.current(), delay);
        };
        subscribe(subscriber);
        return () => {
            clearTimeout(timeout);
            unsubscribe(subscriber);
        };
    }, [delay, subscribe, unsubscribe]);
};

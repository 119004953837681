import invariant from "tiny-invariant";
export const commandHandlingMiddleware = ({ commandDefinitions, queryBus, eventBus }) => async (command) => {
    invariant(commandDefinitions[command.name], `There is no subscribed CommandHandler for the provided Command - ${command.name}`);
    const [commandHandler, commandHandlerDependencies, repository] = commandDefinitions[command.name];
    const { get, save, ...repositoryDependencies } = repository;
    return (async (command) => {
        let state = { aggregateId: command.aggregateId, domainEvents: [] };
        try {
            state = await get({ queryBus, ...repositoryDependencies })(command.aggregateId);
        }
        catch (error) { }
        const aggregateRoot = await commandHandler({
            ...commandHandlerDependencies,
            queryBus,
        })({
            command,
            aggregateRoot: state,
        });
        await save(repositoryDependencies)(aggregateRoot);
        const { domainEvents } = aggregateRoot;
        await eventBus(domainEvents);
    })(command);
};

import { shell } from '@helpers';

import { getUrl } from './modules';

const SHELL_TITLE = 'uploadFile()';

export const uploadFile = async ({ body, endpoint, headers, hostname, method = 'POST' } = {}) =>
  new Promise((resolve, reject) => {
    const url = getUrl({ endpoint, hostname });

    fetch(url, { headers, method, body })
      .then(async (response) => {
        if (response.status >= 400) {
          shell.error(SHELL_TITLE, url, { response });
          reject({ code: response.status });
        } else {
          shell.info(SHELL_TITLE, url, { response });
          resolve(response);
        }
      })
      .catch(({ message = 'Something wrong happened. Try again.', response } = {}) => {
        shell.error(SHELL_TITLE, url, { response, message });

        reject({
          code: response ? response.status : 500,
          message,
        });
      });
  });

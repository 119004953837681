var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { forwardRef, useEffect, useLayoutEffect, useState } from 'react';
import { Animated, Platform } from 'react-native';
import StyleSheet from 'react-native-extended-stylesheet';
import { IS_JEST, capitalize, testID } from '../../../helpers';
import { styles, useBanProps, useBanStylerProps, useDevice, useStyler } from '../../../hooks';
import { buildStyle, calcEasing, calcState, getNativeDriver, toAnimatedValue } from './helpers';
import { BANNED_PROPS, MOTION_TIMING, MOTION_TYPES } from './Motion.definition';
const emptyFn = () => { };
const Motion = forwardRef((_a, ref) => {
    var { children, delay = false, disabled, forceFinish = false, layout, loops = 1, role, timing = MOTION_TIMING.QUAD, type = MOTION_TYPES.EXPAND, value = {}, onCancel = emptyFn, onFinish = emptyFn } = _a, others = __rest(_a, ["children", "delay", "disabled", "forceFinish", "layout", "loops", "role", "timing", "type", "value", "onCancel", "onFinish"]);
    const [active, setActive] = useState(false);
    const [mount, setMount] = useState(false);
    const [state, setState] = useState(calcState({ value, layout }));
    useLayoutEffect(() => {
        setMount(true);
    }, []);
    useEffect(() => {
        if (!mount || (forceFinish && active))
            return;
        if (JSON.stringify(Object.keys(value).sort()) !== JSON.stringify(Object.keys(state).sort())) {
            setState(() => {
                const nextState = calcState({ layout, state, value });
                animate(nextState);
                return nextState;
            });
        }
        else {
            animate(state);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [delay, loops, timing, type, value]);
    const animate = (state) => {
        if (IS_JEST)
            return;
        const duration = others.duration || StyleSheet.value(`$motion${capitalize(type)}`);
        const keys = Object.keys(value);
        const useNativeDriver = getNativeDriver(keys);
        const animations = Animated.parallel(keys
            .filter((key) => state[key] !== undefined)
            .map((key) => Animated.timing(state[key], {
            toValue: toAnimatedValue(key, value[key], layout),
            delay: delay ? duration : 0,
            duration: disabled ? 0 : duration,
            easing: disabled ? undefined : calcEasing(timing),
            useNativeDriver,
        })));
        const callbacks = ({ finished }) => {
            if (!finished) {
                animations.stop();
                onCancel();
            }
            else {
                onFinish();
            }
            if (forceFinish && !disabled)
                setActive(false);
        };
        if (loops === 1)
            animations.start(callbacks);
        else
            Animated.loop(animations, { iterations: loops }).start(callbacks);
        if (forceFinish && !disabled)
            setActive(true);
    };
    return (React.createElement(Animated.View, Object.assign({}, useBanProps(useBanStylerProps(others), BANNED_PROPS), testID(others.testID), { accessibilityRole: Platform.OS === 'web' ? role : undefined, pointerEvents: others.pointerEvents || others.pointer, ref: ref, style: styles(...useStyler(others, Motion.displayName, useDevice()).style, buildStyle(value, disabled || IS_JEST ? value : state)) }), children));
});
Motion.displayName = 'Motion';
export { Motion };

import { Storage, DomainAdapter } from '../Storage';
import { PROXY_STORE_KEY } from './request.config';

export const setProxy = (value) => {
  const store = new Storage({ adapter: DomainAdapter });

  store.set(PROXY_STORE_KEY, typeof value === 'object' ? value : undefined);

  return store.get(PROXY_STORE_KEY);
};

const UNITS = { 1: 'one', 2: 'two' };
const DIMENSIONS = { 2: 'half', 3: 'third', 4: 'quarter' };
const DEFAULT_BREAKPOINT = 'default';
export const determineSize = (size, breakpoints) => {
    let key;
    if (typeof size === 'object') {
        const breakpoint = Object.keys(breakpoints).find((key) => breakpoints[key] === true);
        key = size[breakpoint] || size[DEFAULT_BREAKPOINT] || '';
    }
    else {
        key = size || '';
    }
    const [unit, dimension] = key.split('/');
    const parsedUnit = parseInt(unit, 10);
    const parsedDimension = parseInt(dimension, 10);
    return `${UNITS[parsedUnit]}${DIMENSIONS[parsedDimension]}`;
};

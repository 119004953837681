import { bootstrap as baseBootstrap, } from "@lookiero/messaging";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React, { createContext, useEffect, useMemo } from "react";
import invariant from "tiny-invariant";
const contexts = {};
const getContext = ({ id = "" }) => {
    invariant(contexts[id], `There is no bootstrapped messaging context for: ${id}`);
    return contexts[id];
};
const bootstrap = ({ id = "" } = {}) => {
    const { query, command, processManager: baseProcessManager, build } = baseBootstrap();
    invariant(!contexts[id], `There is already bootstrapped a messaging context for: ${id}`);
    contexts[id] = {
        messaging: createContext({}),
        queryClient: createContext(undefined),
    };
    const instance = {
        query: (name, queryHandler, ...dependencies) => {
            query(name, queryHandler, ...dependencies);
            return instance;
        },
        command: (name, commandHandler, ...dependencies) => (get, save, ...repositoryDependencies) => {
            command(name, commandHandler, ...dependencies)(get, save, ...repositoryDependencies);
            return instance;
        },
        processManager: (name, processManager, ...dependencies) => {
            baseProcessManager(name, processManager, ...dependencies);
            return instance;
        },
        build: () => {
            const { queryBus, commandBus, subscribe, unsubscribe } = build();
            const queryClient = new QueryClient();
            const Component = ({ children, includeReactQueryDevTools, cacheClearOnUnmount = true, }) => {
                const { messaging: MessagingContext, queryClient: queryClientContext } = getContext({ id });
                const value = useMemo(() => ({
                    queryBus,
                    commandBus,
                    subscribe,
                    unsubscribe,
                }), []);
                useEffect(() => () => {
                    if (cacheClearOnUnmount) {
                        queryClient.clear();
                    }
                }, 
                // eslint-disable-next-line react-hooks/exhaustive-deps
                []);
                return (React.createElement(QueryClientProvider, { client: queryClient, context: queryClientContext },
                    includeReactQueryDevTools && React.createElement(ReactQueryDevtools, { initialIsOpen: false, context: queryClientContext }),
                    React.createElement(MessagingContext.Provider, { value: value }, children)));
            };
            Component.displayName = `${id}Messaging` || "Messaging";
            return { commandBus, queryBus, Component };
        },
    };
    return instance;
};
export { getContext, bootstrap };

import { Storage, DomainAdapter } from '../../Storage';
import { HOSTNAME_STORE_KEY, PROXY_STORE_KEY } from '../request.config';

export const getUrl = ({ endpoint = '', hostname = '' } = {}) => {
  const globalHostname = new Storage({ adapter: DomainAdapter }).get(HOSTNAME_STORE_KEY);
  const proxy = new Storage({ adapter: DomainAdapter }).get(PROXY_STORE_KEY);
  const proxyKeys = Object.keys(proxy || {});

  if (proxyKeys.length > 0) {
    const [, baseEndpoint] = endpoint.split('/');

    const proxyKey = proxyKeys.find((key) => key.replace('/*', '') === `/${baseEndpoint}`);

    if (proxyKey) {
      const { changeOrigin, target } = proxy[proxyKey];

      return `${target}${changeOrigin ? endpoint.replace(proxyKey, '') : endpoint}`;
    }
  }

  return `${globalHostname || hostname}${endpoint}`;
};

export class DomainAdapter {
  constructor() {
    window.Lookiero = window.Lookiero || {};
    return this;
  }

  get(key) {
    return window.Lookiero[key];
  }

  set(key, value) {
    if (value) window.Lookiero[key] = value;
    else delete window.Lookiero[key];
  }
}
